/* eslint-disable */
import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import {
  Banner,
} from 'components';
import { BASE_URL } from 'config';

// const showdown = require('showdown');

// const converter = new showdown.Converter();

const MyPhotos = () => (
  <Layout page="about/my-photos">

    <Helmet
      title="Upload Your Tour Photos"
      meta={[
        {
          name: 'description', content: 'Share with us the very best of your tour photos and we’ll put them on our homepage for other travellers to see!',
        },
        { name: 'og:title', content: 'Upload Your Tour Photos' },
        {
          name: 'og:description', content: 'Share with us the very best of your tour photos and we’ll put them on our homepage for other travellers to see!',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/my-photos/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/haka-early-bird-promotion-2.jpg?h=490&bm=screen&fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Upload My Photos</h2>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <h1 className="l-contact-us__title c-heading c-heading--h2 u-text--center">Upload Your Tour Photos</h1>
        <p className="u-text--center">Share with us the very best of your tour photos and we’ll put them on our homepage for other travellers to see!</p>
      </div>
      <br />
      <div className="u-text--center">
        <iframe style={{height: '450px', width: '360px' }} frameBorder="0" src="https://upload.crowdriff.com/haka-tours-136a5561" />
      </div>
    </section>
  </Layout>
);

export default MyPhotos;
